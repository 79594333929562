import { useParams } from 'react-router-dom';

import { LoadingSpinner } from '@components/ScanInfoCard';
import { SegmentInfoCard } from '@components/SegmentInfoCard';
import { Sidebar } from '@components/Sidebar';
import { useFilters } from '@hooks/useFilters';
import { useNavigateExtended } from '@hooks/useNavigateExtended';
import { trackEvent } from '@utils/trackEvent';

export const Page = () => {
  const { segmentId } = useParams();

  const navigate = useNavigateExtended();
  const { sidebar, setSidebar } = useFilters();

  const onSidebarClose = () => {
    navigate(`../..`, {
      relative: 'path',
      preserveHash: true,
      preserveSearch: true,
      preserveSearchExclude: ['sidebar'],
    });
    trackEvent('sidebarClosed');
  };

  return (
    <Sidebar key={segmentId} onClose={onSidebarClose} setSidebar={setSidebar} sidebar={sidebar}>
      {!segmentId ? <LoadingSpinner /> : <SegmentInfoCard segmentId={segmentId} />}
    </Sidebar>
  );
};
