import { useAuth0 } from '@auth0/auth0-react';
import { ArrowDownLine } from '@deepup/icons';
import { IconButton, type SxProps, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { UseImageSourceProps } from '@hooks/useImageSource';
import { useThemeMode } from '@hooks/useThemeMode';
import { fetchImgProxy, getImgProxySearchString } from '@utils/imgProxy';
import { trackEvent } from '@utils/trackEvent';

export type ImageDownloadProps = UseImageSourceProps & {
  id: string;
  name: string;
  sx?: SxProps;
};

export const ImageDownload = ({
  id,
  name,
  sx,
  options: optionsProp,
  extension,
  ...optionsDirectProp
}: ImageDownloadProps) => {
  const { t } = useTranslation();
  const { theme, isDarkMode } = useThemeMode();
  const auth0 = useAuth0();

  // here we build the search string for imgproxy,
  // fetch the image
  // and create a temporary link for triggering the download
  // similar code you find here: useImageSource
  // but cannot be reused since useImageSource is using useEffect
  // and here we want to call it imperatively
  const onClick = async () => {
    const options = { ...optionsProp, ...optionsDirectProp };
    const searchString = getImgProxySearchString({ options, extension });
    const metadata = { photo_id: id };

    try {
      const response = await fetchImgProxy({ id, searchString, auth0 });

      if (response.ok) {
        const imageBlob = await response.blob();
        const objectUrl = URL.createObjectURL(imageBlob);

        const fileLink = document.createElement('a');

        fileLink.href = objectUrl;
        fileLink.download = name;
        fileLink.click();
        URL.revokeObjectURL(objectUrl);
        trackEvent('photoDownloadSuccess', metadata);
      } else {
        trackEvent('photoDownloadFailed', metadata);
        alert(t('components.imageDownload.notFoundMessage'));
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      trackEvent('photoDownloadFailed', metadata);
      alert(t('components.imageDownload.notFoundMessage'));
    }
  };

  return (
    <Tooltip title={t('components.imageDownload.toolTip')}>
      <IconButton
        onClick={onClick}
        sx={{
          background: isDarkMode ? 'rgba(0, 0, 0, 0.7)' : 'rgba(220, 220, 220, 0.7)',
          '&:hover': {
            background: isDarkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(200, 200, 200, 0.7)',
          },
          ...sx,
        }}
      >
        <ArrowDownLine fill={theme.palette.text.primary} />
      </IconButton>
    </Tooltip>
  );
};
