import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import {
  Fragment,
  type Dispatch,
  type ReactElement,
  type SetStateAction,
  type ChangeEvent,
} from 'react';
import { useTranslation } from 'react-i18next';

import { getDifferenceQuantity, isEqual } from '@utils/core';

type CheckBoxGroupProps<T extends string> = {
  availableItems: T[];
  items: T[];
  setItems: Dispatch<SetStateAction<T[]>>;
  disabled?: boolean;
  transformLabel?: (label: T) => T | ReactElement;
  subDivider?: ReactElement | null;
  mainDivider?: ReactElement | null;
};

export const CheckBoxGroup = <T extends string>({
  availableItems,
  items,
  setItems,
  disabled,
  transformLabel,
  subDivider = null,
  mainDivider = <Divider />,
}: CheckBoxGroupProps<T>) => {
  const { t } = useTranslation();
  const isChecked =
    (availableItems.length === 0 && items.length > 0) ||
    (availableItems.length > 0 && isEqual(availableItems, items));
  const isIndeterminate =
    availableItems.length > 0 && items.length > 0 && items.length < availableItems.length;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const isCheckedLocal = event.target.checked;

    if (name === '_ALL_' && (isIndeterminate || !isChecked)) {
      setItems(availableItems);
    } else if (name === '_ALL_' && isChecked) {
      setItems([]);
    } else if (!isCheckedLocal) {
      setItems((prevItems) => prevItems.filter((item) => item !== name));
    } else if (isCheckedLocal) {
      setItems((prevItems) => [...prevItems, name as T]);
    }
  };

  return (
    <FormControl component="fieldset" sx={{ width: '100%' }} variant="standard">
      <FormGroup>
        {availableItems.length || items.length ? (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  disabled={disabled}
                  indeterminate={isIndeterminate}
                  name="_ALL_"
                  onChange={handleChange}
                />
              }
              label={t('components.filterBox.fields.checkBoxGroup.selectAllLabel')}
              sx={{ p: '8px 16px', m: '0 -11' }}
            />

            {mainDivider}
          </>
        ) : (
          <Typography sx={{ padding: '16px' }}>
            {t('components.filterBox.fields.checkBoxGroup.nothingAvailableMessage')}
          </Typography>
        )}

        {availableItems.map((item) => (
          <Fragment key={item}>
            {/* This is a quick implementation of the optional divider for the last filter item.
                TODO: In the near future, we will add even more filter options, so we will need to
                implement a more flexible solution.
            */}
            {subDivider && item === availableItems[availableItems.length - 1] && subDivider}
            <FormControlLabel
              control={
                <Checkbox
                  checked={items.map((t) => t.toString()).includes(item)}
                  disabled={disabled}
                  name={item}
                  onChange={handleChange}
                />
              }
              label={transformLabel ? transformLabel(item) : item}
              sx={{ p: '8px 16px', m: '0 -11' }}
            />
          </Fragment>
        ))}
      </FormGroup>
    </FormControl>
  );
};

export const CheckBoxGroupInversed = <T extends string>({
  availableItems,
  items,
  setItems,
  ...rest
}: CheckBoxGroupProps<T>) => {
  const selectedItems = getDifferenceQuantity(availableItems, items);

  const setSelectedItems = (value: T[] | ((prev: T[]) => T[])) => {
    if (typeof value === 'function') {
      setItems(getDifferenceQuantity(availableItems, value(selectedItems)));

      return;
    }

    setItems(getDifferenceQuantity(availableItems, value));
  };

  return (
    <CheckBoxGroup<T>
      {...rest}
      availableItems={availableItems}
      items={selectedItems}
      setItems={setSelectedItems}
    />
  );
};
