import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ImageCarousel } from '@components/ImageCarousel';
import { PhotoInfo } from '@components/PhotoDownload/PhotoInfo';
import { Sidebar } from '@components/Sidebar';
import { useFilters } from '@hooks/useFilters';
import { useNavigateExtended } from '@hooks/useNavigateExtended';
import { trackEvent } from '@utils/trackEvent';

export const Page = () => {
  const { t } = useTranslation();
  const { photoId, photoIndex } = useParams();
  const photoIds = photoId?.split(',');

  const navigate = useNavigateExtended();
  const { sidebar, setSidebar } = useFilters();

  const isFullscreen = sidebar === 'fullscreen';

  const [expanded, setExpanded] = useState(isFullscreen);

  const onSidebarClose = () => {
    navigate(`../../..`, {
      relative: 'path',
      preserveHash: true,
      preserveSearch: true,
      preserveSearchExclude: ['sidebar'],
    });
    trackEvent('sidebarClosed');
  };

  return (
    <Sidebar
      key={photoIds?.join(',')}
      onClose={onSidebarClose}
      setSidebar={setSidebar}
      sidebar={sidebar}
    >
      {photoIds && photoIds.length === 1 && (
        <PhotoInfo
          expanded={expanded}
          isFullscreen={isFullscreen}
          photoId={photoIds[0]}
          setExpanded={setExpanded}
        />
      )}
      {photoIds && photoIds.length > 1 && (
        <ImageCarousel
          index={!Number.isNaN(Number(photoIndex)) ? Number(photoIndex) : 0}
          label={t('pages.map.photo.imageCarouselLabel')}
          onSlideChange={(index) => {
            navigate(`../${index}`, {
              relative: 'path',
              preserveSearch: true,
              preserveHash: true,
            });
          }}
          slides={photoIds.map((photoId) => (
            <PhotoInfo
              expanded={expanded}
              isFullscreen={isFullscreen}
              key={photoId}
              photoId={photoId}
              setExpanded={setExpanded}
            />
          ))}
          sx={{ height: '100%', width: '100%' }}
          sxControls={{
            alignItems: 'start',
            top: expanded && !isFullscreen ? 'max(100px, 23%)' : '42%',
            height: 'auto',
            left: isFullscreen ? '10px' : '26px',
            right: isFullscreen ? 'calc(min(30%, 400px) + 10px)' : '26px',
          }}
          sxDisplay={{
            width: isFullscreen ? 'calc(100% - min(30%, 400px))' : '100%',
          }}
        />
      )}
    </Sidebar>
  );
};
