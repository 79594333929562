import type { ExpressionSpecification } from '@maplibre/maplibre-gl-style-spec';
import type { Expression } from 'mapbox-gl';

import type { FilterValues, TrassFilter } from '@hooks/useFilters';
import { layingTypeColors, surfaceTypeColors, usageTypeColors } from '@utils/segmentMapping';

export const isHoveredExpression: ExpressionSpecification = [
  'boolean',
  ['feature-state', 'hover'],
  false,
];

export const projectCloseupThreshold = 12;

export const filterTrajectoryExpression = (filterValues: FilterValues): Expression => {
  const beginDate = ['slice', ['get', 'begin_date'], 0, 10];
  const scanDevice = ['get', 'scan_device_name'];
  const projectId = ['get', 'project_id'];
  const surfaceClassification = ['get', 'surface_classification_most_probable_label'];

  return [
    'all',
    ...(filterValues.dateRange.from?.isValid
      ? [['>=', beginDate, filterValues.dateRange.from.toISODate()]]
      : []),
    ...(filterValues.dateRange.to?.isValid
      ? [['<=', beginDate, filterValues.dateRange.to.toISODate()]]
      : []),
    ...(filterValues.scanDevices.length
      ? [['!', ['in', scanDevice, ['literal', filterValues.scanDevices]]]]
      : []),
    ...(filterValues.projectList.length
      ? [['!', ['in', projectId, ['literal', filterValues.projectList]]]]
      : []),
    ...(filterValues.surfaceClassification.length
      ? [['!', ['in', surfaceClassification, ['literal', filterValues.surfaceClassification]]]]
      : []),
    ...(filterValues.surfaceClassification.length &&
    filterValues.surfaceClassification.includes('undefined')
      ? [['has', 'surface_classification_most_probable_label']]
      : []),
  ];
};

// Beginning from 2024-01-01 we do generate ar-trajectories for scans.
// All scans older than this date are considered old scans.
const OLD_SCANS_DATE_LIMIT = '2024-01-01';

export const filterOldScansExpression = (filterValues: FilterValues): Expression => {
  const beginDate = ['slice', ['get', 'begin_date'], 0, 10];
  const scanDevice = ['get', 'scan_device_name'];
  const projectId = ['get', 'project_id'];

  return [
    'all',
    ...(filterValues.dateRange.from?.isValid
      ? [
          [
            '>=',
            beginDate,
            filterValues.dateRange.from.toISODate() >= OLD_SCANS_DATE_LIMIT
              ? OLD_SCANS_DATE_LIMIT
              : filterValues.dateRange.from.toISODate(),
          ],
        ]
      : []),
    ...(filterValues.dateRange.to?.isValid
      ? [
          [
            '<=',
            beginDate,
            filterValues.dateRange.to.toISODate() >= OLD_SCANS_DATE_LIMIT
              ? OLD_SCANS_DATE_LIMIT
              : filterValues.dateRange.to.toISODate(),
          ],
        ]
      : [['<=', beginDate, OLD_SCANS_DATE_LIMIT]]),
    ...(filterValues.scanDevices.length
      ? [['!', ['in', scanDevice, ['literal', filterValues.scanDevices]]]]
      : []),
    ...(filterValues.projectList.length
      ? [['!', ['in', projectId, ['literal', filterValues.projectList]]]]
      : []),
  ];
};

export const filterProjectsExpression = (filterValues: FilterValues): Expression => {
  const projectId = ['get', 'id'];

  return [
    'all',
    ...(filterValues.projectList.length
      ? [['!', ['in', projectId, ['literal', filterValues.projectList]]]]
      : []),
  ];
};

export const filterPhotoExpression = (filterValues: FilterValues): Expression => {
  const date = ['slice', ['get', 'date'], 0, 10];
  const scanDevice = ['get', 'scan_device_name'];
  const category = ['get', 'category_name'];
  const projectId = ['get', 'project_id'];

  return [
    'all',
    ...(filterValues.dateRange.from?.isValid
      ? [['>=', date, filterValues.dateRange.from.toISODate()]]
      : []),
    ...(filterValues.dateRange.to?.isValid
      ? [['<=', date, filterValues.dateRange.to.toISODate()]]
      : []),
    ...(filterValues.scanDevices.length
      ? [['!', ['in', scanDevice, ['literal', filterValues.scanDevices]]]]
      : []),
    ...(filterValues.photoCategories.length
      ? [['!', ['in', category, ['literal', filterValues.photoCategories]]]]
      : []),
    ...(filterValues.projectList.length
      ? [['!', ['in', projectId, ['literal', filterValues.projectList]]]]
      : []),
  ];
};

export const filterTrassSegmentExpression = (filterValues: FilterValues): Expression => {
  const date = ['slice', ['get', 'scan_date'], 0, 10];
  const scanDevice = ['get', 'scan_device'];
  const projectId = ['get', 'project_id'];
  const activeTrassFilter = [
    'get',
    filterValues.activeTrassFilter === 'USAGE'
      ? 'usage_type'
      : filterValues.activeTrassFilter === 'LAYING'
        ? 'laying_type'
        : 'surface_type',
  ];
  const activeTrassFilterValue =
    filterValues.activeTrassFilter === 'USAGE'
      ? filterValues.usageTypes
      : filterValues.activeTrassFilter === 'LAYING'
        ? filterValues.layingTypes
        : filterValues.activeTrassFilter === 'SURFACE'
          ? filterValues.surfaceTypes
          : [];

  return [
    'all',
    filterValues.showTrasses,
    ...(filterValues.dateRange.from?.isValid
      ? [['>=', date, filterValues.dateRange.from.toISODate()]]
      : []),
    ...(filterValues.dateRange.to?.isValid
      ? [['<=', date, filterValues.dateRange.to.toISODate()]]
      : []),
    ...(filterValues.scanDevices.length
      ? [['!', ['in', scanDevice, ['literal', filterValues.scanDevices]]]]
      : []),
    ...(filterValues.projectList.length
      ? [['!', ['in', projectId, ['literal', filterValues.projectList]]]]
      : []),
    ...(activeTrassFilterValue.length
      ? [['!', ['in', activeTrassFilter, ['literal', activeTrassFilterValue]]]]
      : []),
  ];
};

export const isScanExpression = (
  scanId: string | undefined,
  elementType: string | undefined,
  elementTypeToMatch: string,
): Expression => [
  'all',
  ['==', ['to-string', ['get', 'scan_id']], scanId ?? ''],
  ['==', elementType ?? '', elementTypeToMatch],
];

export const isTrassExpression = (fid: string | undefined): Expression => [
  '==',
  ['to-string', ['get', 'fid']],
  fid ?? '',
];

export const isFeatureExpression = (id?: string): Expression => [
  '==',
  ['to-string', ['get', 'id']],
  id ?? '',
];

const trassColorMapping = {
  LAYING: layingTypeColors,
  USAGE: usageTypeColors,
  SURFACE: surfaceTypeColors,
  // only placeholder, color for TRENCH_PROFILE comes from trassTrenchProfileColorExpression expression
  TRENCH_PROFILE: {},
};

export const trassColorExpression = (type: TrassFilter): Expression => [
  'to-color',
  [
    'case',
    ['has', `${type.toLowerCase()}_type`],
    ['get', ['get', `${type.toLowerCase()}_type`], ['literal', trassColorMapping[type]]],
    'grey',
  ],
];

export const trassTrenchProfileColorExpression = (
  type: 'width' | 'depth',
  range: number[],
): Expression => [
  'to-color',
  [
    'case',
    ['all', ['==', type, 'width'], ['has', 'width'], ['>', ['get', 'width'], 0]],
    [
      'case',
      ['all', ['>=', ['get', 'width'], range[0]], ['<=', ['get', 'width'], range[1] ?? 200]],
      '#00A887',
      '#EF4447',
    ],
    ['all', ['==', type, 'depth'], ['has', 'depth'], ['>', ['get', 'depth'], 0]],
    [
      'case',
      ['all', ['>=', ['get', 'depth'], range[0]], ['<=', ['get', 'depth'], range[1] ?? 200]],
      '#00A887',
      '#EF4447',
    ],
    '#666666',
  ],
];
