import { LngLat } from 'mapbox-gl';
import { useEffect, useState } from 'react';
import { toLatLon } from 'utm';

import { useApiHdMesh } from '@hooks/useApiHdMesh';

const fetchOffset = async (offsetUrl: string) => {
  const response = await fetch(offsetUrl);

  return response.json();
};

type Status = 'success' | 'loading' | 'error';

export const useCoordinates = (scanId: string) => {
  const [coordinates, setCoordinates] = useState<LngLat | null>(null);
  const [status, setStatus] = useState<Status>('loading');
  const { data: manifest, status: meshStatus } = useApiHdMesh(scanId);

  useEffect(() => {
    if (meshStatus === 'error') {
      setStatus('error');

      return;
    }

    if (!manifest) return;

    const asyncCall = async () => {
      try {
        const offsetUrl = manifest.files['offsets.json'];
        const response = await fetchOffset(offsetUrl);

        const { longitude, latitude } = toLatLon(
          response.offset.x,
          response.offset.y,
          response.zone,
          'U',
        );
        const lngLat = new LngLat(longitude, latitude);

        setCoordinates(lngLat);
        setStatus('success');
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        setCoordinates(null);
        setStatus('error');
      }
    };

    asyncCall();
  }, [manifest, meshStatus]);

  return { coordinates, status };
};
