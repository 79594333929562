import { DateTime } from 'luxon';
import type { MapboxGeoJSONFeature } from 'mapbox-gl';
import { useEffect, useState } from 'react';

const getValueFromUnknownProp = <T>(
  obj: { [p: string]: T } | null,
  maybeProps: string[],
): T | null => {
  if (!obj) {
    return null;
  }

  for (let i = 0; i < maybeProps.length; i++) {
    if (Object.hasOwn(obj, maybeProps[i])) {
      return obj[maybeProps[i]];
    }
  }

  return null;
};

export const useMinDateFromFeatures = (features: MapboxGeoJSONFeature[]) => {
  const [minDate, setMinDate] = useState<DateTime | undefined>(undefined);

  useEffect(() => {
    // The date property is called different on the features, so we have to try these:
    // trajectories -> begin_date
    // photos -> date
    // trasses -> scan_date
    const dateProps = ['begin_date', 'date', 'scan_date'];

    let minDate = null;

    for (const feature of features) {
      const date = getValueFromUnknownProp(feature.properties, dateProps);

      if (date) {
        // get yyyy-mm-dd part from date
        const sliced = date.slice(0, 10);

        if (minDate === null || sliced < minDate) {
          minDate = sliced;
        }
      }
    }

    if (minDate !== null) {
      setMinDate(DateTime.fromISO(minDate));
    } else {
      setMinDate(undefined);
    }
  }, [features]);

  return minDate;
};
