import { photoClientApiV1 as photoClientApi } from '@deepup/apis';
import type { ListPhotosResponse } from '@deepup/apis/dist/typescript/photo/v1/photo';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useGrpcRestTransport } from '@hooks/useGrpcRestTransport';

export const useListPhotos = (projectId: string | null) => {
  const transport = useGrpcRestTransport();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['photoClient.listPhotos', projectId],
    queryFn: async () => {
      if (projectId) {
        const photoClient = new photoClientApi.PhotoServiceClient(transport);
        const PAGE_SIZE = 1000;

        const listPhotos = await photoClient.listPhotos({
          projectId,
          options: {
            // as long as filters are not supported by API all photos must be fetched in one call
            // possibly will be fixed in next iteration
            pageSize: PAGE_SIZE,
            page: BigInt(0),
            contextId: '',
            withPaginationInfo: true,
          },
        }).response;

        listPhotos.items?.forEach((photoItem) => {
          queryClient.setQueryData(['photoClient.getPhoto', photoItem.id], photoItem);
        });

        const totalPages = Number(listPhotos.paginationInfo?.totalPages);

        if (totalPages <= 1) {
          return listPhotos;
        }

        const pages: Promise<ListPhotosResponse>[] = [];

        // query starting from 2nd page, as 1st was done above
        for (let i = 1; i < totalPages; i++) {
          pages.push(
            photoClient.listPhotos({
              projectId,
              options: {
                // as long as filters are not supported by API all photos must be fetched in one call
                // possibly will be fixed in next iteration
                pageSize: PAGE_SIZE,
                page: BigInt(i),
                contextId: '',
                withPaginationInfo: true,
              },
            }).response,
          );
        }

        const compoundPages = await Promise.all(pages);
        const compoundPhotoItems = compoundPages.flatMap((page) => page.items);

        compoundPhotoItems?.forEach((photoItem) => {
          queryClient.setQueryData(['photoClient.getPhoto', photoItem.id], photoItem);
        });

        return {
          items: [...listPhotos.items, ...compoundPhotoItems],
        };
      }
    },
    enabled: !!projectId,
  });
};

export const usePhotoItem = (id: string) => {
  const transport = useGrpcRestTransport();

  return useQuery({
    queryKey: ['photoClient.getPhoto', id],
    queryFn: () => {
      const photoClient = new photoClientApi.PhotoServiceClient(transport);

      return photoClient.getPhoto({ id }).response;
    },
  });
};
