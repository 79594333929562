import { photoApiV1 as photoApi } from '@deepup/apis';
import { Clear } from '@mui/icons-material';
import { FormControl, IconButton, Stack, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DateRangePicker } from '@components/FilterBox/fields/DateRangePicker';
import { SelectBoxMultiInversed } from '@components/FilterBox/fields/SelectBoxMulti';
import { SearchNotPossible } from '@components/SearchNotPossible';
import { useListUsedScanners } from '@hooks/useApiDevice';
import { useListPhotos } from '@hooks/useApiPhoto';
import type { ProjectEntity } from '@hooks/useApiProjects';
import { distinct } from '@utils/core';
import type { DateRange } from '@utils/types';

import { DownloadButton } from './DownloadButton/DownloadButton';
import { PhotoSidebar } from './PhotoSidebar';
import { ProjectAutocomplete } from './ProjectAutocomplete';
import { PhotoTable } from './Table/Table';
import { isDateInRange, isInCategories, isInScanDevices, isInTextSearch } from './utils';

const defaultToDate = DateTime.now();
const defaultFromDate = defaultToDate.minus({ days: 10 });

const INITTIAL_DATE_RANGE = {
  to: defaultToDate,
  from: defaultFromDate,
};

export const PhotoDownload = () => {
  const { t } = useTranslation();
  const [project, setProject] = useState<ProjectEntity | null>(null);
  const [photo, setPhoto] = useState<photoApi.Photo | null>(null);

  const [dateRange, setDateRange] = useState<DateRange>(INITTIAL_DATE_RANGE);
  const [scanDevices, setScanDevices] = useState<string[]>([]);
  const [photoCategories, setPhotoCategories] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  const { data: photoData, isLoading } = useListPhotos(project?.id || null);

  useEffect(() => {
    if (photoData) {
      setScanDevices([]);
      setSearch('');
      setDateRange(INITTIAL_DATE_RANGE);
    }
  }, [photoData]);

  const photos = photoData?.items || [];

  const { data: usedScannersData } = useListUsedScanners({
    projectIds: project ? [project.id] : [],
    usedBefore: dateRange?.to ?? INITTIAL_DATE_RANGE.to,
  });

  useEffect(() => {
    if (usedScannersData) {
      setScanDevices([]);
    }
  }, [usedScannersData]);

  const scanners = usedScannersData?.items || [];

  const availableDevices = distinct(scanners.map((item) => item.serialNumber));
  const availableCategories = distinct(photos.map((item) => item.categoryName));

  const filteredPhotos = photos
    .filter(isInTextSearch(search))
    .filter(isDateInRange(dateRange))
    .filter(isInScanDevices(scanDevices, availableDevices, scanners))
    .filter(isInCategories(photoCategories, availableCategories));

  return (
    <>
      <Stack component="div" mt={2} p={2} spacing={4}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <ProjectAutocomplete project={project} setProject={setProject} />
          {project && <DownloadButton photos={filteredPhotos} project={project} />}
        </Stack>
        {!!photos.length && (
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
            <SelectBoxMultiInversed
              availableItems={availableCategories}
              items={photoCategories}
              label={t('components.filterBox.photoCategorySelect.label')}
              setItems={setPhotoCategories}
            />
            <SelectBoxMultiInversed
              availableItems={availableDevices}
              items={scanDevices}
              label={t('components.filterBox.scanDeviceSelect.label')}
              setItems={setScanDevices}
            />
            <FormControl fullWidth>
              <TextField
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setSearch('')}
                      sx={{ display: search ? 'visible' : 'none' }}
                    >
                      <Clear />
                    </IconButton>
                  ),
                }}
                fullWidth
                label={t('pages.map.photos.search.label')}
                onInput={(e) => setSearch((e.target as HTMLInputElement).value)}
                placeholder={t('pages.map.photos.search.label')}
                value={search}
              />
            </FormControl>
          </Stack>
        )}

        {project ? (
          <PhotoTable
            isLoading={isLoading}
            photos={filteredPhotos}
            scanners={scanners}
            setPhoto={setPhoto}
          />
        ) : (
          <SearchNotPossible>
            {t('components.photoDownload.errors.noProjectSelected')}
          </SearchNotPossible>
        )}
      </Stack>
      {photo && <PhotoSidebar photo={photo} setPhoto={setPhoto} />}
    </>
  );
};
