import { deviceManagementClientApiV1, timestampApi } from '@deepup/apis';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { useGrpcRestTransport } from '@hooks/useGrpcRestTransport';

type Payload = {
  projectIds: string[];
  usedBefore: DateTime;
};

export const useListUsedScanners = ({ projectIds, usedBefore }: Payload) => {
  const transport = useGrpcRestTransport();
  const usedBeforeDate = usedBefore.toJSDate();

  return useQuery({
    queryKey: ['deviceManagementApi', 'listUsedScanners', ...projectIds, usedBeforeDate],
    queryFn: async () => {
      const client = new deviceManagementClientApiV1.DeviceManagementServiceClient(transport);

      const scanners = await client.listUsedScanners({
        projectIds,
        usedByPhotos: true,
        usedByScans: false,
        usedBefore: timestampApi.Timestamp.fromDate(usedBeforeDate),
        options: {
          // as long as filters are not supported by API all photos must be fetched in one call
          // possibly will be fixed in next iteration
          pageSize: 1000,
          page: BigInt(0),
          contextId: '',
          withPaginationInfo: true,
        },
      }).response;

      return scanners;
    },
    enabled: !!projectIds.length,
  });
};
