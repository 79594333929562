import { LoadingScreen } from '@deepup/loading-screen';
import { Alert, Box, type Theme } from '@mui/material';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import { type SetStateAction, type Dispatch } from 'react';

import { Image } from '@components/Image';
import { ImageDownload } from '@components/ImageDownload';
import { ImageZoom } from '@components/ImageZoom';
import { PhotoInfoCard } from '@components/PhotoInfoCard';
import { useListUsedScanners } from '@hooks/useApiDevice';
import { usePhotoItem } from '@hooks/useApiPhoto';
import { indexList } from '@utils/core';

import { getPhotoFilename } from './utils';

type Props = {
  photoId: string;
  isFullscreen: boolean;
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
};

export const PhotoInfo = ({ photoId, isFullscreen, expanded, setExpanded }: Props) => {
  const { data: photoData, isLoading, isError } = usePhotoItem(photoId);

  const { data: usedScannersData } = useListUsedScanners({
    projectIds: photoData ? [photoData.projectId] : [],
    // rounding to an hour is sufficient to avoid drilling down the date range
    usedBefore: DateTime.now().startOf('hour'),
  });

  if (isLoading || !photoData) {
    return <LoadingScreen />;
  }
  if (isError) {
    return <Alert severity="error">{t('components.photoDownload.errors.photo')}</Alert>;
  }
  const scanners = usedScannersData?.items || [];
  const scannersIndex = indexList(scanners);

  const imageName = getPhotoFilename(photoData);

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: isFullscreen ? 'row' : 'column',
        padding: isFullscreen ? 0 : '16px',
        overflow: 'auto',
      }}
    >
      <Box
        component="div"
        sx={(theme: Theme) => ({
          flex: 1,
          height: '100%',
          width: '100%',
          minHeight: '200px',
          background: theme.palette.grey[50],
          borderRadius: isFullscreen ? '0' : '10px',
          overflow: 'hidden',
          position: 'relative',
        })}
      >
        <ImageDownload
          id={photoId}
          name={imageName}
          options={{ stripMetadata: false }}
          sx={{ position: 'absolute', top: '10px', right: '60px', zIndex: 1 }}
        />
        <ImageZoom
          image={
            <Image
              alt={`${t('pages.map.photo.imageAltCategory')}:${photoData?.categoryName}`}
              height={2000}
              id={photoId}
              sx={{
                width: '100%',
                height: '100%',
                display: 'block',
              }}
              sxLoading={{
                width: '100%',
                height: '700px',
              }}
            />
          }
          padding={0}
        />
      </Box>
      <Box
        component="div"
        sx={{
          width: isFullscreen ? 'min(30%, 400px)' : 'auto',
          overflowY: 'auto',
        }}
      >
        <PhotoInfoCard
          collapsable={!isFullscreen}
          expanded={expanded}
          photoData={photoData}
          scanner={scannersIndex[photoData.deviceId]}
          setExpanded={setExpanded}
        />
      </Box>
    </Box>
  );
};
