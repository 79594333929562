import { DateTime } from 'luxon';
import { type Expression, type MapboxGeoJSONFeature } from 'mapbox-gl';
import { useEffect, useState } from 'react';

import { type ScanInfo } from '@components/ScanInfoCard';
import { useMapFeatures } from '@hooks/useMapFeatures';

const toScanInfo = (feature: MapboxGeoJSONFeature | undefined): ScanInfo | undefined => {
  if (
    feature &&
    feature.id &&
    feature.properties &&
    feature.properties['begin_date'] &&
    feature.properties['end_date'] &&
    feature.properties['scan_device_name']
  ) {
    const beginDatetime = DateTime.fromSQL(feature.properties['begin_date'], { zone: 'utc' });
    const endDatetime = DateTime.fromSQL(feature.properties['end_date'], { zone: 'utc' });
    const duration = endDatetime.diff(beginDatetime);

    return {
      id: feature.id.toString(),
      date: beginDatetime,
      duration: duration,
      device: feature.properties['scan_device_name'],
      length: feature.properties['length'] ?? null,
    };
  }

  return undefined;
};

const scanIDFilter = (scanId: string | undefined): Expression => [
  '==',
  ['get', 'scan_id'],
  scanId ?? '',
];

export const useFetchScanInfo = (scanId?: string) => {
  // fetch mapbox features and filter for scanId
  const features = useMapFeatures(
    {
      source: 'trajectories-source',
      sourceLayer: 'artrajectory',
      filter: scanIDFilter(scanId),
    },
    {
      source: 'oldscans-source',
      sourceLayer: 'notrajectory',
      filter: scanIDFilter(scanId),
    },
  );

  const [scanInfo, setScanInfo] = useState<ScanInfo>();

  // assign scanInfo by scanId
  useEffect(() => {
    // don't unset scanInfo if scanId hasn't changed
    if (scanInfo?.id === scanId) return;

    setScanInfo(toScanInfo(features?.[0]));
  }, [scanId, scanInfo?.id, features]);

  return { scanInfo };
};
