import { GrpcRestFetchTransport, useAuth0Interceptor } from '@deepup/api-utils';
import { useMemo, useContext, createContext, type ReactNode } from 'react';

const GrpcRestTransportContext = createContext<GrpcRestFetchTransport | null>(null);

import { getEnvironment } from '@utils/getEnvironment';
const { apiUnified } = getEnvironment();

type Props = { children: ReactNode };

export const GrpcRestTransportProvider = ({ children }: Props) => {
  const authInterceptor = useAuth0Interceptor();

  const transportRest = useMemo(() => {
    return new GrpcRestFetchTransport({
      baseUrl: apiUnified,
      fetchInit: {
        credentials: 'include',
      },
      interceptors: [authInterceptor],
    });
  }, [authInterceptor]);

  return (
    <GrpcRestTransportContext.Provider value={transportRest}>
      {children}
    </GrpcRestTransportContext.Provider>
  );
};

export const useGrpcRestTransport = () => {
  const context = useContext(GrpcRestTransportContext);

  if (!context) {
    throw new Error('useGrpcRestTransport must be used within a GrpcRestTransportProvider');
  }

  return context;
};
