import { Source } from 'react-map-gl';

import { LayerOrder } from '@components/MapSource/LayerOrder';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { getEnvironment } from '@utils/getEnvironment';

import { OldScans } from './OldScans';
import { Photos } from './Photos';
import { Plandata } from './Plandata';
import { ProjectPolygons } from './ProjectPolygons';
import { Trajectories } from './Trajectories';
import { Trasses } from './Trasses';

export const makeSourceURL = (layerName: string) => {
  const { wmtsSource } = getEnvironment();

  return wmtsSource.replace('{layer_name}', layerName);
};

export const MapSource = () => {
  const { isOldscansEnabled } = useFeatureFlags();

  const projectSource = makeSourceURL('PROJECT');
  const trajectorySource = makeSourceURL('TRAJECTORY');
  const photoSource = makeSourceURL('PHOTO');
  const trassSource = makeSourceURL('TRASS_SEGMENTED');
  const plandataSource = makeSourceURL('PLANDATA');
  const oldScansSource = makeSourceURL('NOTRAJECTORY');

  return (
    <>
      <Source
        id="photos-source"
        promoteId={'id' /* features don't always have ids, so we use id property instead*/}
        tiles={[photoSource]}
        type="vector"
      />
      <Source
        id="trajectories-source"
        promoteId={
          'scan_id' /* features don't always have ids, so we use scan_id property instead*/
        }
        tiles={[trajectorySource]}
        type="vector"
      />
      <Source
        id="projects-source"
        promoteId={'id' /* features don't always have ids, so we use id property instead*/}
        tiles={[projectSource]}
        type="vector"
      />
      <Source id="trass-source" promoteId={'fid'} tiles={[trassSource]} type="vector" />
      <Source id="plandata-source" tiles={[plandataSource]} type="vector" />
      {isOldscansEnabled && (
        <Source id="oldscans-source" promoteId={'scan_id'} tiles={[oldScansSource]} type="vector" />
      )}
      <LayerOrder>
        <ProjectPolygons source="projects-source" sourceLayer="project" />
        <Photos source="photos-source" sourceLayer="photo" />
        <OldScans source="oldscans-source" sourceLayer="notrajectory" />
        <Trajectories source="trajectories-source" sourceLayer="artrajectory" />
        <Trasses source="trass-source" sourceLayer="trass_segmented" />
        <Plandata source="plandata-source" />
      </LayerOrder>
    </>
  );
};
