import {
  photoApiV1 as photoApi,
  commentApiV1 as commentApi,
  deviceManagementApiV1,
} from '@deepup/apis';
import { EditNote } from '@mui/icons-material';
import { IconButton, Stack, Table, TableBody, Typography } from '@mui/material';
import type { TFunction } from 'i18next';
import { useId, useState, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, type AccordionControlProps } from '@components/Accordion';
import { CommentForm, PostComment } from '@components/PhotoDownload/PostComment';
import { TableCell, TableCellText, TableRow } from '@components/Table';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { localizeTimestampApi } from '@utils/timeFormatting';

export type PhotoInfoCardProps = AccordionControlProps & {
  photoData: photoApi.Photo;
  scanner: deviceManagementApiV1.ScannerPreview;
  children?: ReactNode;
};

export type PhotoInfo = {
  id: string;
  address?: string;
  category?: string;
  date: string;
  device: string;
  solution: string;
  text?: string;
  comments?: commentApi.Comment[];
  commentsDevice?: commentApi.Comment[];
};

const translateGeolocation = (t: TFunction) => ({
  [photoApi.GeolocationSolution.NONE]: t('hooks.useFetchPhotoInfo.NONE'),
  [photoApi.GeolocationSolution.CODE]: t('hooks.useFetchPhotoInfo.CODE'),
  [photoApi.GeolocationSolution.CORELOCATION]: t('hooks.useFetchPhotoInfo.CORELOCATION'),
  [photoApi.GeolocationSolution.DGNSS]: t('hooks.useFetchPhotoInfo.DGNSS'),
  [photoApi.GeolocationSolution.FLOAT]: t('hooks.useFetchPhotoInfo.FLOAT'),
  [photoApi.GeolocationSolution.FIXED]: t('hooks.useFetchPhotoInfo.FIXED'),
});

const mapPhotoInfoCard = (photo: photoApi.Photo) => ({
  id: photo.id,
  address: `${photo.location?.place} ${photo.location?.city}`,
  category: photo.categoryName,
  date: localizeTimestampApi(photo.recordedAt) as string,
  device: photo.deviceId,
  solution: photo.geoPositionSolution,
  comments: photo.comments.filter((item) => item.type === commentApi.CommentType.NOTE),
  commentsDevice: photo.comments.filter((item) => item.type === commentApi.CommentType.DEVICE),
});

export const PhotoInfoCard = ({
  expanded,
  setExpanded,
  collapsable,
  photoData,
  scanner,
}: PhotoInfoCardProps) => {
  const { t } = useTranslation();
  const [editCommentId, setEditCommentId] = useState<string | null>(null);
  const { isCockpitMigrationEnabled } = useFeatureFlags();
  const commentKeyId = useId();
  const commentDeviceKeyId = useId();
  const info = mapPhotoInfoCard(photoData);
  const geolocationDescriptions = translateGeolocation(t);

  return (
    <Accordion
      collapsable={collapsable}
      expanded={expanded}
      setExpanded={setExpanded}
      title={t('components.photoInfoCard.title')}
    >
      <Table
        sx={{
          width: '100%',
          '& tr:last-child td': { borderWidth: collapsable ? 0 : 1 },
        }}
      >
        <TableBody>
          {!info && (
            <TableRow>
              <TableCellText>{t('components.photoInfoCard.info.noData')}</TableCellText>
              <TableCellText></TableCellText>
            </TableRow>
          )}
          {info && (
            <>
              <TableRow>
                <TableCellText>{t('components.photoInfoCard.info.id')}</TableCellText>
                <TableCellText>{info.id}</TableCellText>
              </TableRow>
              <TableRow>
                <TableCellText>{t('components.photoInfoCard.info.address')}</TableCellText>
                <TableCellText>{info.address}</TableCellText>
              </TableRow>
              <TableRow>
                <TableCellText>{t('components.photoInfoCard.info.category')}</TableCellText>
                <TableCellText>{info.category}</TableCellText>
              </TableRow>
              <TableRow>
                <TableCellText>{t('components.photoInfoCard.info.date')}</TableCellText>
                <TableCellText>{info.date}</TableCellText>
              </TableRow>
              <TableRow>
                <TableCellText>{t('components.photoInfoCard.info.precision')}</TableCellText>
                <TableCellText>{geolocationDescriptions[info.solution]}</TableCellText>
              </TableRow>
              <TableRow>
                <TableCellText>{t('components.photoInfoCard.info.device')}</TableCellText>
                <TableCellText>{scanner?.serialNumber ? scanner.serialNumber : ''}</TableCellText>
              </TableRow>
              <TableRow>
                <TableCellText showBorder={!isCockpitMigrationEnabled ? undefined : true}>
                  {t('components.photoDownload.table.commentDevice')}
                </TableCellText>
                {info.commentsDevice?.length ? (
                  <TableCell showBorder={!isCockpitMigrationEnabled ? undefined : true}>
                    {info.commentsDevice.map((comment, i) => {
                      return (
                        <Typography key={`${commentDeviceKeyId}-${i}`} variant="body2">
                          {comment.text}
                        </Typography>
                      );
                    })}
                  </TableCell>
                ) : (
                  <TableCellText showBorder={!isCockpitMigrationEnabled ? undefined : true}>
                    {' '}
                  </TableCellText>
                )}
              </TableRow>
              {isCockpitMigrationEnabled && (
                <TableRow>
                  <TableCellText>{t('components.photoInfoCard.info.comment')}</TableCellText>
                  <TableCell>
                    {info.comments?.length ? (
                      <Stack component="div" spacing={2}>
                        {info.comments.map((comment, i) => {
                          if (editCommentId !== null && comment.id === editCommentId) {
                            return (
                              <CommentForm
                                initialText={comment.text}
                                key={comment.id}
                                onClose={() => setEditCommentId(null)}
                                photoId={info.id}
                              />
                            );
                          }

                          return (
                            <Stack
                              alignItems="center"
                              component="div"
                              direction="row"
                              key={`${commentKeyId}-${i}`}
                              spacing={1}
                            >
                              {comment.text}
                              <IconButton onClick={() => setEditCommentId(comment.id)}>
                                <EditNote />
                              </IconButton>
                            </Stack>
                          );
                        })}
                      </Stack>
                    ) : (
                      <PostComment photoId={info.id} />
                    )}
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </Accordion>
  );
};
