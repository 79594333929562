import { Auth0Provider } from '@auth0/auth0-react';
import { ThemePaletteModeContext, ThemeProvider, useDeepUpTheme } from '@deepup/mui-theme-deepup';
import { createTheme, CssBaseline } from '@mui/material';
import { deDE as coreDeDE, enUS as coreEnUS } from '@mui/material/locale';
import { deDE as dataGridDeDE, enUS as dataGridEnUS } from '@mui/x-data-grid-pro/locales';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { deDE, enUS } from '@mui/x-date-pickers-pro/locales';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { SnackbarProvider } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Auth } from '@components/Auth';
import { FlagsmithInit } from '@components/FlagsmithInit';
import { SnackbarNotification } from '@components/Snackbar';
import { GrpcRestTransportProvider } from '@hooks/useGrpcRestTransport';
import { getEnvironment } from '@utils/getEnvironment';

import { AppRoutes } from './AppRoutes';

import './App.css';
import './Typeform.css';
import '@utils/sentrySetup';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // invalidate manually after mutation calls
      staleTime: Infinity,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 0,
    },
  },
});

export const App = () => {
  const { auth0ClientId, auth0Domain, auth0Audience } = getEnvironment();
  const { i18n } = useTranslation();

  const { themePaletteModeContextValue, theme } = useDeepUpTheme();

  const getLocale = () => {
    const { language } = i18n;

    if (language === 'de') {
      return {
        core: coreDeDE,
        datePicker: deDE,
        dataGrid: dataGridDeDE,
      };
    }

    return {
      core: coreEnUS,
      datePicker: enUS,
      dataGrid: dataGridEnUS,
    };
  };

  const locale = getLocale();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locale.datePicker, locale.dataGrid, locale.core),
    [locale.core, locale.datePicker, locale.dataGrid, theme],
  );

  // set html palette mode className
  useEffect(() => {
    const htmlElement = document.querySelector('html');

    if (htmlElement) {
      htmlElement.className = theme.palette.mode;
    }
  }, [theme]);

  return (
    <>
      <CssBaseline />
      <SnackbarProvider
        Components={{
          default: SnackbarNotification,
          success: SnackbarNotification,
          error: SnackbarNotification,
          warning: SnackbarNotification,
          info: SnackbarNotification,
        }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      />
      <FlagsmithProvider flagsmith={flagsmith}>
        <ThemePaletteModeContext.Provider value={themePaletteModeContextValue}>
          <ThemeProvider theme={themeWithLocale}>
            <Auth0Provider
              authorizationParams={{
                redirect_uri: window.location.origin,
                audience: auth0Audience,
              }}
              clientId={auth0ClientId ?? ''}
              domain={auth0Domain ?? ''}
            >
              <Auth>
                <FlagsmithInit>
                  <GrpcRestTransportProvider>
                    <QueryClientProvider client={queryClient}>
                      <LocalizationProvider
                        adapterLocale={i18n.language}
                        dateAdapter={AdapterLuxon}
                      >
                        <AppRoutes />
                      </LocalizationProvider>
                    </QueryClientProvider>
                  </GrpcRestTransportProvider>
                </FlagsmithInit>
              </Auth>
            </Auth0Provider>
          </ThemeProvider>
        </ThemePaletteModeContext.Provider>
      </FlagsmithProvider>
    </>
  );
};
