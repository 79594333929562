import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer } from 'react-map-gl';
import { useParams } from 'react-router-dom';

import { MapPopup } from '@components/MapPopup';
import type { VectorLayerProps } from '@components/MapSource/VectorLayerProps';
import { useFilters } from '@hooks/useFilters';
import { useMapFeatureState } from '@hooks/useMapFeatureState';
import { useMapHoverFeature } from '@hooks/useMapHoverFeature';
import { useMapSelectFeatures } from '@hooks/useMapSelectFeatures';
import { useNavigateExtended } from '@hooks/useNavigateExtended';
import {
  filterTrassSegmentExpression,
  isHoveredExpression,
  isTrassExpression,
  projectCloseupThreshold,
  trassColorExpression,
  trassTrenchProfileColorExpression,
} from '@utils/mapboxExpressions';
import { getDepthWidthInfo } from '@utils/segmentMapping';

export const Trasses = ({ source, sourceLayer }: VectorLayerProps) => {
  const { segmentId } = useParams();
  const { feature: hoveredFeature } = useMapHoverFeature('trass-layer');
  const { selectedFeature } = useMapSelectFeatures(['trass-layer']);
  const navigate = useNavigateExtended();
  const filters = useFilters();
  const filter = filterTrassSegmentExpression(filters);
  const { t } = useTranslation();

  useEffect(() => {
    const trassSegmentId = selectedFeature?.properties?.fid;

    if (trassSegmentId) {
      navigate(`segment/${trassSegmentId}`, { preserveSearch: true, preserveHash: true });
    }
    // https://github.com/remix-run/react-router/issues/7634
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature]);

  useMapFeatureState(hoveredFeature, { hover: true });

  if (!filters.showTrasses) {
    return;
  }

  return (
    <>
      {hoveredFeature && filters.activeTrassFilter === 'TRENCH_PROFILE' && (
        <>
          <MapPopup>
            <Typography sx={{ padding: '0 5px' }}>
              {
                getDepthWidthInfo(
                  t,
                  filters.trenchProfileFilter,
                  filters.trenchProfileRange,
                  hoveredFeature?.properties?.width,
                  hoveredFeature?.properties?.depth,
                )['text']
              }
            </Typography>
          </MapPopup>
        </>
      )}
      <Layer
        filter={filter}
        id="trass-layer"
        layout={{ 'line-cap': 'round' }}
        minzoom={projectCloseupThreshold}
        paint={{
          'line-color': [
            'case',
            ['==', filters.activeTrassFilter, 'TRENCH_PROFILE'],
            trassTrenchProfileColorExpression(
              filters.trenchProfileFilter,
              filters.trenchProfileRange,
            ),
            trassColorExpression(filters.activeTrassFilter),
          ],
          'line-width': ['case', ['any', isTrassExpression(segmentId), isHoveredExpression], 7, 4],
        }}
        source={source}
        source-layer={sourceLayer}
        type="line"
      />
    </>
  );
};
